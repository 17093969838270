@use "../../styles/foundation/mixins";
@import "../../styles/foundation/variables";

.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.content {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.icon {
  background-color: $category-icon-background-color;
  border-radius: $border-radius;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    font-size: 34px;
    color: #ffffff;
    filter: drop-shadow(0px 0px 2px black);
  }
}

.textContent {
  height: 80px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.details {
  color: #353534;
  font-size: 14px;
  font-weight: 700;
}

.startButton {
  @include mixins.accentButton;
  height: 42px;
  width: 100%;
  font-size: 16px;
}
