.container {
  width: 100%;
  position: relative;
  height: 60px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;


  .userId {
    position: absolute;
    left: 0px;
    color: #6f6f6f;
    font-weight: 600;

    span {
      font-weight: 700;
      font-style: italic;
    }

  }

  .dropdown {
    position: absolute;
    right: 0px;

    label {
      margin-left: 4px;
      font-size: 10px;
      color: grey;
    }

  }

}