$accent-color: #d45097;
$primary-color: #3b3c3b;
$primary-color-dark: #353534;
$category-icon-background-color: #3b3c3b;
$font-dark: #231f20;

/* 
 * Configurarable border radius for Buttons & Category Icons
 * None
 * Small (i.e. 5px)
 * Medium (i.e. 12 px)
 * Big (full radius)
 */
$border-radius: 12px;

$header-height: 102px;
$max-content-width: 850px;
$breakpoint-mobile: 550px;
$breakpoint-mobile-small: 475px;
