@use "../../styles/foundation/mixins";
@import "../../styles/foundation/variables";

.container {
  @include mixins.flexCenterColumn;
  gap: 32px;
  text-align: center;

  svg {
    font-size: 60px;
    color: $primary-color-dark;
  }
}
