@import "./styles/foundation/variables";

.main {
  width: 100vw;
  height: 100vh;
  height: 100dvh; //Fixes height for some mobile devices
  overflow: hidden;
}
.content {
  width: 100%;
  height: 100%;
  max-width: $max-content-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
