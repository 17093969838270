
.title {
    margin-bottom: 10px;
    margin-left: 8px;
    font-weight: 600;
}

.listBox {
    padding: 0px;
    margin-top: 0px;
    .state {
        height: 30px;
        border: 1.33px solid #6F6F6F;
        border-top: 0px;
        display: flex;
        flex-direction: row;

        .stateName {
            width: 30%;
            text-align: left;
            margin-left: 20px;
            line-height: 2;
            color: #3C3C3B;
            font-weight: 600;
            font-size: 14px;
        }

        .stateDatetime {
            width: 66%;
            text-align: right;
            font-style: italic;
            font-size: 11px;
            line-height: 2.4;
        }
        
        .stateImg {
            width: 14%;
            text-align: right;
            display:flex;
            align-items: center;
            justify-content: center;
            img {
                height: 60%;
            }
        }
    }
    
}


.listBox:last-child li:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.listBox li:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top: 1.33px solid #6F6F6F;
}



@media (max-width: 360px) {
    
    .listBox {
        .state {
            .stateName {
                font-size: 12px;
            }
        }
    }
}