@import "./../../styles/foundation/variables";

.main {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 50px 25px 0px 25px;
}

.hiddenScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hiddenScroll::-webkit-scrollbar {
  display: none;
}

.mainContent {
  flex: 1;
}

.scrollSpacer {
  width: 25px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 32px;
  margin-top: 20px;
  img {
    //width: 90px;
    opacity: 0.5;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .content::-webkit-scrollbar {
    display: none;
  }

  .content {
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 25px 12px 0px 12px;
  }

  .scrollSpacer {
    display: none;
  }
}
