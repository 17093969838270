@import "variables";

//Header
h1 {
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}

h2 {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  color: $font-dark;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin: 4px 0;
  color: $font-dark;
}

@media screen and (max-width: $breakpoint-mobile) {
  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }
}

@media screen and (max-width: $breakpoint-mobile-small) {
  h1 {
    font-size: 14px;
  }
}
