@use "../../../../styles/foundation/mixins";
@import "../../../../styles/foundation/variables";

.main {
  background: rgba(0, 0, 0, 0.3);
  padding: 24px 50px 16px;
  display: flex;
  height: 100%;

  ::-webkit-scrollbar-track {
    margin: 6px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 25px 25px 8px;
  }
}

.content {
  width: 100%;
  height: 50vh;
  background: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
  padding: 24px 25px 24px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px 12px 12px 24px;
  }
}

.text {
  overflow: auto;
  overflow-x: hidden;
  flex: 1;
  padding-right: 25px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-right: 12px;
  }
}

.buttonWrap {
  @include mixins.flexCenter;
  gap: 10px;
}

.acceptButton {
  @include mixins.accentButtonFullRadius;
}

.cancelButton {
  @include mixins.outlineButtonFullRadius;
}
