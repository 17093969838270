.buttonBox {

    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 50px;

    .continueButton {
        background-color: #CB3B92;
        border: 0px;
        border-radius: 19.6px;
        width: 23%;
        height: 34px;
        font-weight: 800;
        font-size: 18px;
        color: white;
        //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }
    .submitButton {
        background-color: white;
        border: 1.4px solid #6F6F6F;
        border-radius: 19.6px;
        width: 23%;
        height: 34px;
        font-weight: 800;
        font-size: 18px;
        color: #3C3C3B;
        cursor: pointer;
    }
}

@media (max-width: 414px) {
    
    .buttonBox {

        column-gap: 20px;

        .continueButton {
            width: 40%;
            height: 50px;
            
        }
        .submitButton {
            width: 40%;
            height: 50px;
            
        }
    }
}



