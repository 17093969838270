.uploadBtnWrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	width: 100%;
}
.btn {
	width: 100%;
	color: white;
	background-color: #4b8df8;
	padding: 0.5em;
	font-size: 20px;
	font-weight: bold;
}
label[for="proof"] {
	color: white;
	background-color: #4b8df8;
	padding: 0.5em;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
}
.uploadBtnWrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
.previewLoadedImage{
	position: relative;
    height: auto;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#newImagesList{
	list-style: none;
	text-align: center;
	padding-left: 0px;
}
ul#newImagesList li{
	margin: 5px 20px;
	display: inline-flex;
}
ul#newImagesList li > img {
	border: solid 1px #D0E9F5;
}

ul#newImagesList li {
	//display: inline;
}

.loadedImage{
	width: 100%;
	cursor: pointer;
	opacity: 0.7;
}
.previewLoadedImage .hoverBox {
	display: block;
	position: absolute;
	opacity: 0;
}
.previewLoadedImage:hover .hoverBox {
    display: block;
	opacity: 1;
	position: absolute;
	align-items: center;
	justify-content: center;

}
.hoverBox img {
	display: block;
	margin: auto auto;
    height: 30px;
	cursor: pointer;
}

@media (min-width: 992px){
	.container {
		width: 70% !important;
	}
}