@use "../../styles/foundation/mixins";
@import "../../styles/foundation/variables";

.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detailsContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 10px;
}

.textContent {
  height: 80px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  line-height: 16px;
  white-space: wrap;
  width: 65%;

}

.statusContent {
  height: 80px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  line-height: 16px;
  justify-content: center;
  align-items: flex-end;
  width: 15%;


  .dateTime {
    font-size: 12px;
    font-weight: 400;
    color:#474747;
    font-style: italic;
  }

  .statusText {
    font-weight: 700;
    color:#6F6F6F;
  }

}

.statusIconContent {
  height: 80px;
  display: flex;
  row-gap: 6px;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  width: 5%;
}


.header {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;
  white-space: nowrap;
  row-gap: 6px;
  color: black;
}

%statusCard {
  width: fit-content;
  padding: 0px 6px;
  color: #ffffff;
  font-weight: 800;
  font-size: 9px;
}

.new {
  @extend %statusCard;
  background-color: $accent-color;
  text-transform: uppercase;
}

.lowCompletes {
  @extend %statusCard;
  background-color: $primary-color-dark;
  text-transform: lowercase;
}

.offerIcon {
  border-radius: 14px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-right: 0px;

}

.details {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  color: $primary-color-dark;
  white-space: wrap;
  span {
    font-size: 13px;
    font-weight: 700;
  }

  .propertyRow {
    display: flex;
    flex-direction: row;
    height: 22px;
    column-gap: 6px;
    margin-top: 4px;
  }

  .propertyItem {
    border: 0.5px solid #6F6F6F;
    background-color: white;
    border-radius: 8px;
  }

  .propertyItemImg {
    @extend .propertyItem;
    padding: 2px 9px;
    object-fit: contain;
    width: 15px;
  }

  .propertyItemStat {
    @extend .propertyItem;
    padding: 0px 7px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.8;
    span.current {
      font-size: 10px;
      font-weight: 700;
      color: rgb(184, 59, 184);
    }
    span.max {
      color: black;
      font-weight: 700;
      font-size: 10px;
    }
    img {
      height: 8px;
      margin-right: 2px;
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    align-items: center;
  }

  .desc {
    white-space: wrap;
    width: 70%;
    word-wrap: break-word;   
    overflow-wrap: break-word;
    word-break: break-word;
    color: black;
    font-size: 10px;
    margin-top: 4px;

  }
}

.startButton {
  @include mixins.accentButton;
  height: 42px;
  width: 100%;
  font-size: 16px;
  line-height: 45px;
  white-space: nowrap;
}





@media screen and (max-width: $breakpoint-mobile) {
  .container {
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .detailsContent {
    flex: 0 0 220px;
  }

  .startButton {
    height: 58px;
    padding: 4px 15px;
  }

  .offerIcon {
    height: 60px;
    width: 60px;
  }

  .header {
    white-space: break-spaces;
    font-size: 12px;
  }

  .details {
    .propertyItemImg {
      width: 10px;
      height: 10px;
      padding: 2px 4px;
    }  
    .desc {
      width: inherit;
    }
    .propertyRow {
     height: 16px;
     column-gap: 2px;
    }  
    .propertyItemStat {
      white-space: nowrap;
      font-size: 8px;
      span.current {
        font-size: 8px;
      }
      span.max {
        font-size: 8px;
      }
      img {
        display: none;
      }
    }
  }

  .statusContent {

    .statusText {
      font-size: 8px;
    }

  }  

}