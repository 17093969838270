.sectionInput {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        margin-bottom: 10px;
        font-weight: 700;
        color: black;
    }

    .campaignName {
        margin-left: 20px;
        color: #6f6f6f;
        font-weight: 500;
    }

    .messageInput {
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 8px;
        height: 180px;
    }

    .emailInput {
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 8px;
        height: 30px;

    }

    .desc {
        font-size: 12px;
        text-align: center;
        font-weight: 700;
    }

    .descAppend {
        font-size: 11px;
        text-align: center;
        font-weight: 700;
        color: #d45097;
    }

    .bottom {
        display: flex;
        flex-direction: row;
        margin-top: 4px;

        .left {
            width: 50%;
            font-size: 11px;
            text-align: left;
        }

        .right {
            width: 50%;
            font-size: 11px;
            text-align: right;   
        }

        .uploadButton {
            background-color: #5094f9;
            border-radius: 8px;
            border: none;
            display: inline-flex;
            padding: 4px 8px;
            color: white;
            font-weight: 700;
            font-size: 12px;
            cursor: pointer;
  
        }
    }

    .limitBox {
        font-size: 14px;
    }

    .submitButton {
        background-color: #d45097;
        width: 24%;
        border-radius: 24px;
        color: white;
        font-size: 24px;
        padding: 8px 2px;
        font-weight: 700;
        border: none;
        cursor: pointer;
    }

}

.sectionInputOffer {
    @extend .sectionInput;
    margin-top: 30px;
}

.sectionInputSelectTask {
    @extend .sectionInput;
    margin-top: 40px;
}

.sectionInputEmail {
    @extend .sectionInput;
    margin-top: 25px;
}

.sectionInputMessage {
    @extend .sectionInput;
    margin-top: 25px;
}

.sectionInputDesc {
    @extend .sectionInput;
    margin-top: 60px;
}

.sectionInputButton {
    @extend .sectionInput;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}



@media (max-width: 414px){

    .sectionInput {
        .campaignName {
            margin-left: 0px;
        }
        .submitButton {
            width: 80%
        }
    }    

}