@import "./../../styles/foundation/variables";

.surveyContainer {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 40px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .surveyContainer {
    grid-template-columns: 1fr;
  }
}
