@use "../../styles/foundation/mixins";
@import "./../../styles/foundation/variables";

.container {
  flex: 1;
  display: grid;
  grid-template-areas: "overlay";
  grid-template-columns: minmax(0, 1fr);
}

.backgroundContent {
  grid-area: overlay;
  display: flex;
}

.declinedContainer {
  @include mixins.flexCenterColumn;
}

.declinedContentContainer {
  width: 100%;
  max-width: 600px;
  @include mixins.flexCenterColumn;
  text-align: center;
  gap: 20px;

  svg {
    font-size: 60px;
    color: $primary-color-dark;
  }

  p {
    font-size: 18px;
    line-height: normal;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    p {
      font-size: 14px;
    }
  }
}

.overlay {
  grid-area: overlay;
  z-index: 5;
}
