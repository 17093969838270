.title {
    margin-bottom: 10px;
    margin-left: 8px;
    font-weight: 600;
}

.listBox {
    padding: 0px;
    margin-top: 0px;
    .task {
        height: 30px;
        padding: 4px 0px;
        border-bottom: 1.60px solid #D9D9D9;
        border-top: 0px;
        display: flex;
        flex-direction: row;
        color: #3C3C3B;
        align-items: center;

        .taskDesc {
            width: 30%;
            text-align: left;
            margin-left: 20px;
            line-height: 1.8;
            font-size: 12px;
            font-weight: 700;
        }

        .taskCost {
            width: 53%;
            text-align: right;
            line-height: 2.2;
            font-size: 13px;
            font-weight: 700;
        }
        
        .taskImg {
            width: 9%;
            text-align: right;
            display:flex;
            align-items: center;
            justify-content: end;
            img {
                height: 40%;
            }
        }
    }
    
}


.listBox:last-child li:last-child {
    border-bottom: 0px;
}

