
.container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;


    .offerImgBox {
        .offerImg {
            border-radius: 20px;
            height: 80px;
     }
    }

    .offerInfoBox {

        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        margin-left: 20px;

        .offerName {
            font-size: 20px;
            font-weight: 700;
            
        }
        .offerDesc {
            font-size: 11px;
            font-weight: 600;
            color: #3C3C3B;
        }
    }

}
