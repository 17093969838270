@use "../../styles/foundation/mixins";
@import "../../styles/foundation/variables";

.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detailsContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 10px;
}

.textContent {
  height: 80px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
}

.header {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  row-gap: 6px;
}

%statusCard {
  width: fit-content;
  padding: 0px 6px;
  color: #ffffff;
  font-weight: 800;
  font-size: 9px;
}

.new {
  @extend %statusCard;
  background-color: $accent-color;
  text-transform: uppercase;
}

.lowCompletes {
  @extend %statusCard;
  background-color: $primary-color-dark;
  text-transform: lowercase;
}

.details {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  color: $primary-color-dark;

  span {
    font-size: 11px;
    font-weight: 700;
  }

  .item {
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    align-items: center;
  }
}

.startButton {
  @include mixins.accentButton;
  height: 42px;
  width: 100%;
  font-size: 16px;
  line-height: 45px;
  white-space: nowrap;
}

.locked {
  cursor: default;

  .header {
    color: rgba(0, 0, 0, 0.5);
  }

  .details {
    color: rgba(53, 53, 52, 0.5);
  }

  .statusCard {
    color: #dbdbdb;
    .new {
      background-color: #858584;
    }
  }

  .startButton,
  .notification {
    background-color: #858584;
    color: #dbdbdb;
    cursor: default;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .detailsContent {
    flex: 0 0 220px;
  }

  .startButton {
    height: 58px;
    padding: 4px 15px;
  }
}
