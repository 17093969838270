
.title {
    margin-bottom: 10px;
    margin-left: 8px;
    font-weight: 600;
}

.detailsBox {
    border: 1px solid black;
    border-radius: 10px;

    .titleDesc {
        text-align: center;
        margin: 10px 0px;
        font-weight: 600;
    }
}

.propertiesBox {
    display: flex;
    flex-direction: row;
    margin: 20px 0px; 

    .property {

        display: flex;
        flex-direction: column;
        row-gap: 4px;
        justify-content: center;
        width: 33%;

        .propertyImg {
           width: 10%;
           margin-left: auto;
           margin-right: auto;
        }

        .propertyCount {
            text-align: center;
            font-weight: 700;
            color: #CB3B92;
        }

        .propertyText {
            text-align: center;
            font-size: 10px;
        }   
    }
}


@media (max-width: 414px) {
    
    .detailsBox {
        .titleDesc {
            font-size: 10px
        }
    }

    .propertiesBox {
        .property {    
            .propertyImg {
               width: 30%;
            }
    
            .propertyCount {
                
            }
    
            .propertyText {
                font-size: 10px;
            }   
        }
    }



}