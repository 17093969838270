.noOffersFirstLine {
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
}

.noOffersSecondLine {
    font-size: 16px;
    font-weight: 500;
} 

.buttonBackBox {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


