body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Font
* {
  font-family: "Open Sans";
}

// Scrollbar
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: #d4d4d4;
  margin: 50px;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #b3b3b2;
  border-radius: 6px;
}
