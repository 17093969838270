@use "../../styles/foundation/mixins";
@import "../../styles/foundation/variables";

.container {
  @include mixins.flexCenter;
  flex-shrink: 0;
  border-radius: $border-radius;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

.locked {
  @extend .container;
  background-color: #858584;
  color: #dbdbdb;
  cursor: default;

  svg {
    width: 70%;
    height: 70%;
    font-size: 34px;
    color: #ffffff;
    filter: drop-shadow(0px 0px 2px gray);
  }
}
