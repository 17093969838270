@import "variables";

%buttonBase {
  padding: 4px 26px;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin accentButton {
  @extend %buttonBase;
  background-color: $accent-color;
  border-radius: $border-radius;
  box-shadow: none;
  color: #ffffff;
}

@mixin accentButtonFullRadius {
  @extend %buttonBase;
  min-width: 100px;
  background-color: $accent-color;
  border-radius: 16px;
  color: #ffffff;
}

@mixin outlineButtonFullRadius {
  @extend %buttonBase;
  min-width: 100px;
  background-color: #ffffff;
  border-radius: 16px;
  color: #3c3835;
  border: 1px solid #3c3835;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexCenterColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
