
.title {
    margin-bottom: 16px;
    margin-left: 8px;
    font-weight: 600;
}

.faqQuestion {
    font-size: 12px;
    font-weight: 700;

}

.faqAnswer {
    font-size: 12px;

}



